<template>
  <div class="bg-[rgb(30,30,46)] text-[#E8E8E8]" style="word-break:keep-all">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div v-for="(item, index) in items" :key="index" class="bg-[#2C2C3E] text-[#E8E8E8] p-6 rounded-lg shadow-md border border-[#3A3A4A]">
      <div class="flex justify-center mb-4">
        <component :is="item.icon" class="w-12 h-12 text-[#4A90E2]" />
      </div>
      <h2 class="text-xl font-semibold mb-2 text-center">{{ item.title }}</h2>
      <p class="text-sm text-center">{{ item.description }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Globe, TrendingUp, Smartphone, Tools, BarChart2, Scale } from 'lucide-vue-next'

export default defineComponent({
  components: {
    Globe,
    TrendingUp,
    Smartphone,
    Tools,
    BarChart2,
    Scale
  },
  setup() {
    const items = [
      {
        icon: 'Globe',
        title: '모든 구독자',
        description: '출근 길에 읽을 수 있게 매일 아침마다 IT뉴스,오픈소스,해외블로그 글을 보내드려요.:)'
      },
      {
        icon: 'TrendingUp',
        title: '월간 멤버십',
        description: '매주 1회, 개발 관련 상세한 아티클 또는 직접 겪은 일을 에세이 형태로 보내드립니다.'
      },
      // {
      //   icon: 'Smartphone',
      //   title: '모바일 및 웹 앱',
      //   description: '우리의 모바일 및 웹 앱 선택으로 기술의 경계를 넓히세요. 여러분의 혁신을 공유하고 기술을 재정의하는 앱을 탐험하세요.'
      // },
      // {
      //   icon: 'Tools',
      //   title: 'AI 개발 도구',
      //   description: '우리의 AI 개발 도구로 미래를 형성하세요. 여러분의 도구를 제출하거나 AI 개발을 가속화하고 효율적으로 혁신할 수 있는 리소스를 찾으세요.'
      // },
      // {
      //   icon: 'BarChart2',
      //   title: 'AI 분석',
      //   description: 'AI 분석 도구로 가시성을 확보하세요. 데이터를 전략적 인사이트로 변환하는 기술을 탐험하세요.'
      // },
      // {
      //   icon: 'Scale',
      //   title: 'AI 윤리',
      //   description: '윤리적 AI 실천에 중점을 둔 도구와 리소스를 공유하여 책임 있는 AI 혁신을 촉진하세요.'
      // }
    ]

    return { items }
  }
})
</script>
