<template>
  <div class="mb-12 relative">
    <h2 class="text-3xl font-bold mb-6 flex items-center text-gray-800 flex-wrap">
      <component :is="icon" class="mr-3 w-8 h-8 text-blue-600 flex-shrink-0" />
      <span class="break-words">{{ title }}</span>
    </h2>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div v-for="article in articles" :key="article.id" class="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
        <div class="bg-white shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
          <a :href="`http://stranger.im/redirect?url=${article.url}`" target="_blank">
            <img :src="article.image" :alt="article.title" class="w-full h-48 object-cover" />
          </a>
          <div class="absolute w-full bottom-0 left-0 bg-black bg-opacity-75 text-white p-2 h-[35%] justify-center flex flex-col">
            <h3 class="text-center font-bold min-[480px]:text-sl whitespace-normal overflow-hidden line-clamp-2">{{ article.title }}</h3>
          </div>
        </div>
        <div class="p-6">
          <div v-if="!article.expanded" class="mb-4">
            <ul class="list-none pl-0">
              <li v-for="(point, index) in article.three_line_summary" :key="index" class="text-gray-600 mb-1 flex">
                <span class="mr-2 flex-shrink-0">•</span>
                <span>{{ point }}</span>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="text-gray-600 mb-4">{{ article.detailed_summary }}</p>
            <div class="mb-4">
              <h4 class="font-semibold text-gray-800 mb-2">기술 영향</h4>
              <p class="text-gray-600">{{ article.tech_impact }}</p>
            </div>
            <div class="mb-4">
              <h4 class="font-semibold text-gray-800 mb-2">개발자 팁</h4>
              <p class="text-gray-600">{{ article.developer_takeaway }}</p>
            </div>
          </div>
          <div class="flex justify-between items-center mt-4">
            <a @click="openModal(article.id)" class="text-blue-600 hover:text-blue-800 focus:outline-none transition duration-300">
              <span class="mr-2">인사이트 확보📚</span>
            </a>
            <a :href="`http://stranger.im/redirect?url=${article.url}`" target="_blank" rel="noopener noreferrer" class="px-4 py-2 bg-[#4A90E2] text-white rounded-full hover:bg-[#3A80D2] transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center">
              <span class="mr-2">원문 보기</span>
              <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5 9.00001L21.5 3.00001M21.5 3.00001H15.5M21.5 3.00001L12.5 12M10.5 3H8.3C6.61984 3 5.77976 3 5.13803 3.32698C4.57354 3.6146 4.1146 4.07354 3.82698 4.63803C3.5 5.27976 3.5 6.11984 3.5 7.8V16.2C3.5 17.8802 3.5 18.7202 3.82698 19.362C4.1146 19.9265 4.57354 20.3854 5.13803 20.673C5.77976 21 6.61984 21 8.3 21H16.7C18.3802 21 19.2202 21 19.862 20.673C20.4265 20.3854 20.8854 19.9265 21.173 19.362C21.5 18.7202 21.5 17.8802 21.5 16.2V14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <button @click="scrollToTop" class="fixed bottom-8 right-8 bg-blue-600 bg-opacity-50 text-white p-3 rounded-full shadow-lg hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300" :class="{ 'opacity-0': !showScrollTop }">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
      </svg>
    </button>
    <!-- Modal -->
    <div v-if="isModalOpen" class="modal fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
     <div class="modal-content bg-white p-6 rounded-lg shadow-lg relative max-w-full md:max-w-2xl overflow-auto"
       :style="{ minHeight: 'auto', maxHeight: '90vh', padding: 'initial'}">
        <div id="modalBody">
        </div>
        <button @click="closeModal" class="close-button">&times;</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';

const props = defineProps({
  icon: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  articles: {
    type: Array,
    required: true
  }
});

const isModalOpen = ref(false);
const openModal = async (articleId) => {
  const response = await fetch(`/detail/${articleId}`);
  const html = await response.text();
  if(html !== ""){
    isModalOpen.value = true;
    nextTick(() =>{
        document.getElementById('modalBody').innerHTML = html;
    });
  }
};

const closeModal = () => {
  isModalOpen.value = false;
  document.getElementById('modalBody').innerHTML = '';
};

const showScrollTop = ref(false);

const checkScroll = () => {
  showScrollTop.value = window.pageYOffset > 300;
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
  props.articles.forEach(article => {
    article.expanded = false;
    article.id =  article.id || 'undefined';
    article.three_line_summary = article.three_line_summary || ['요약 정보가 없습니다.'];
    article.detailed_summary = article.detailed_summary || '상세 요약 정보가 없습니다.';
    article.tech_impact = article.tech_impact || '기술 영향 정보가 없습니다.';
    article.developer_takeaway = article.developer_takeaway || '개발자 팁 정보가 없습니다.';
  });
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});
</script>

<style scoped>
@media (max-width: 640px) {
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #f0f0f0;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease, background-color 0.3s ease;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: rgba(230, 0, 35, 0.8); /* 반투명 빨간색 배경 */
}
.close-icon {
  font-family: Arial, sans-serif;
  font-weight: bold;
}
</style>
