<template>
  <ITNewsWebsite />
</template>

<script>
import ITNewsWebsite from './components/ITNewsWebsite.vue'

export default {
  name: 'App',
  components: {
    ITNewsWebsite
  }
}
</script>
